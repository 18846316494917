import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import { motion } from "framer-motion"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import BackgroundImage from "gatsby-background-image"
import Text from "../components/Text"
import Image from "gatsby-image"
import Crate from "../components/Crate"

// Static assets
import Info from "../images/info.svg"

// CSS
import rewardStyles from "../css/rewards.module.css"

const Rewards = () => {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "background_header_blurred.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout diffBackground>
      <div className="min-h-newsHeaderSmall">
        <BackgroundImage
          fluid={data.background.childImageSharp.fluid}
          className="h-full bg-center bg-cover"
        >
          <Section className="flex flex-col items-center justify-center h-full md:justify-between md:flex-row">
            <Image
              fluid={data.logo.childImageSharp.fluid}
              alt="SpaceDelta Logo"
              className="block w-40 mb-8 md:mb-0 md:w-full lg:w-1/2 md:mx-4 md:order-2"
            />
            <button className="block w-full mb-6 md:mb-0 focus:outline-none md:order-1 group">
              <Text variant="h3">
                <span className="block font-semibold text-center uppercase transition-all duration-300 md:text-left lg:text-sm group-hover:opacity-100 group-focus:opacity-100 focus:outline-none ease-easeInOutQuint opacity-80 focus:opacity-100 hover:opacity-100">
                  CLICK TO COPY SERVER IP
                </span>
                <span className="block text-lg font-medium text-center lowercase md:text-left lg:text-3xl">
                  Spacedelta.net
                </span>
              </Text>
            </button>

            <a
              href="https://discord.gg/spacedelta"
              rel="noopener noreferrer"
              tabIndex={-1}
              className="block w-full focus:outline-none md:order-3 group"
            >
              <Text variant="h3">
                <span
                  tabIndex={0}
                  className="block font-semibold text-center uppercase transition-all duration-300 md:text-right lg:text-sm group-hover:opacity-100 focus:outline-none ease-easeInOutQuint opacity-80 focus:opacity-100 hover:opacity-100"
                >
                  CLICK TO JOIN OUR DISCORD
                </span>
                <span className="block text-lg font-medium text-center md:text-right lg:text-3xl">
                  321 Online
                </span>
              </Text>
            </a>
          </Section>
        </BackgroundImage>
      </div>
      <Section>
        <Text
          variant="p"
          className="mb-8 text-xs font-semibold uppercase xxl:text-right xxl:transform xxl:w-40 xxl:-rotate-90 xxl:-translate-x-32 xxl:translate-y-24 xxl:mb-0 md:mb-8"
        >
          - Crates -
        </Text>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <Crate name="Daily Crate" id="001" />
          <Crate name="Daily Crate #2" id="002" />
          <Crate name="Daily Crate #3" id="003" />
        </div>
        <div
          className={`${rewardStyles.specialCrateContainer} mt-12 p-4 md:p-8 lg:p-12`}
        >
          {/* Placeholder  */}
          <div className="relative flex-1 lg:col-span-3 lg:mr-8 xl:mr-24 xxl:mr-32">
            <div className={rewardStyles.placeholder}>&nbsp;</div>
            <svg
              className="relative hidden xl:absolute xl:inset-0 xl:h-full xl:block xl:transform xl:w-full xl:translate-x-4 xl:-translate-y-2 xl:scale-110"
              viewBox="0 0 1051.932 387.799"
            >
              <path
                className={rewardStyles.pathContainer}
                data-name="Path 57"
                d="M1 1.001v224.828l160.969 160.97h888.962V163.194L889.338 1.601z"
                fill="none"
                strokeWidth={2}
              />
            </svg>
            <svg
              className="relative hidden xl:absolute xl:inset-0 xl:h-full xl:block xl:transform xl:w-full xl:translate-x-4 xl:-translate-y-2 xl:scale-110"
              viewBox="0 0 1051.932 387.799"
            >
              <defs>
                <filter
                  id="prefix__a"
                  x={0}
                  y={0}
                  width={1069.932}
                  height={405.799}
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy={3} />
                  <feGaussianBlur stdDeviation={3} result="blur" />
                  <feFlood floodColor="#c521ff" floodOpacity={0.161} />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g filter="url(#prefix__a)">
                <path
                  className={rewardStyles.path}
                  data-name="Path 58"
                  d="M1 1.001v224.828l160.969 160.97h888.962V163.194L889.338 1.601z"
                  fill="none"
                  strokeWidth={2}
                />
              </g>
            </svg>
          </div>
          {/* Content */}
          <div className="flex flex-col items-center justify-center lg:justify-end lg:items-end xl:pb-8">
            <div className="flex items-center mt-5 mb-2 ">
              <p className="text-xs font-semibold tracking-wider">
                Crate ID: ???
              </p>
              <button className="w-4 h-4 ml-2">
                <img src={Info} alt="Information" />
              </button>
            </div>

            <h2 className="text-2xl font-semibold tracking-wider uppercase lg:text-3xl xl:text-5xl lg:text-right">
              Special Crate
            </h2>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default Rewards
