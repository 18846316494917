import React from "react"

// Static assets
import Info from "../images/info.svg"

// CSS
import rewardStyles from "../css/reward.module.css"

interface Props {
  name: string
  thumbnail: any
  id: string
}

const Crate = ({ name, thumbnail, id }: Props) => {
  return (
    <div className="p-4 bg-gray-600 sm:p-6 md:p-8 lg:p-10">
      {/* Thumbnail */}
      <div className="relative group">
        <div className="absolute inset-0 z-10 w-full h-48 transition-all duration-500 transform scale-110 translate-y-8 border-2 border-gray-500 xl:translate-y-12 xl:h-56 group-hover:scale-105 ease-easeInOutQuint">
          &nbsp;
        </div>
        <div className="overflow-hidden">
          <img
            src="https://via.placeholder.com/500"
            className={`group-hover:scale-110 ${rewardStyles.thumbnail}`}
          ></img>
        </div>
      </div>

      <div className="flex items-center mt-5 mb-2">
        <p className="text-xs font-semibold tracking-wider">Crate ID: {id}</p>
        <button className="w-4 h-4 ml-2">
          <img src={Info} alt="Information" />
        </button>
      </div>
      <h2 className="text-2xl font-semibold tracking-wider uppercase">
        {name}
      </h2>
    </div>
  )
}

export default Crate
